import React, { ChangeEvent, KeyboardEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import { getSearchResult, setSearchText, setSearchVisibility } from '../../../actions/search.actions';
import { showNotificationByType } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import messages from './SearchField.messages';
import style from './SearchField.scss';
import { useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Keys } from '../../../utils/keys';
import { ISearchRuleWithValueId } from '../AttributeFilter/AttributeFilter.types';
import { SearchSelectors } from '@/selectors/dbSearch.selector';
import {
    NodeId,
    SearchRequestNodeTypesEnum,
    SearchRequestSearchVisibilityEnum,
    SearchRuleQueryRuleEnum,
} from '@/serverapi/api';
import { hasUserShowDeletedAccess } from '@/selectors/authorization.selectors';
import { Select } from '../../UIKit/components/Select/Select.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import {
    RuleType
} from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';

type TSearchFieldProps = {
    nodeId: NodeId;
    searchRules: ISearchRuleWithValueId[];
    searchNodeTypes: SearchRequestNodeTypesEnum[];
};

const searchVisibilitySearchParamOptions: SearchRequestSearchVisibilityEnum[] = [
    'NOT_DELETED',
    'ALL_INCLUDE_DELETED',
    'DELETED',
];

export const SearchField: FC<TSearchFieldProps> = ({ nodeId, searchNodeTypes, searchRules }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus);
    const searchText: string = useSelector(SearchSelectors.getSearchText(nodeId));
    const rootSearchNodeId: NodeId = useSelector(SearchSelectors.getRootSearchNodeId(nodeId));
    const queryRules: SearchRuleQueryRuleEnum[] = [
        RuleType.HAS_VALUE,
        RuleType.HAS_NOT_VALUE,
        RuleType.HAS_NOT_VALUE_OR_ABSENT,
        RuleType.IS_ABSENT
    ];
    const searchVisibility: SearchRequestSearchVisibilityEnum = useSelector(
        SearchSelectors.getSearchVisibility(nodeId),
    );

    const setSearchTextHandler = (searchText: string) => {
        dispatch(setSearchText(nodeId, searchText));
    };

    const setSearchVisibilityHandler = (visibility: SearchRequestSearchVisibilityEnum) => {
        dispatch(setSearchVisibility(nodeId, visibility));
    };

    const handleSearch = () => {
        const filteredSearchRules = searchRules.map((rule) => {
            const values = rule.values.filter((val) => val.value !== '');
            return {
                ...rule,
                values,
            };
        });
        const hasAttributeFilterError = filteredSearchRules.some(
            (rule) =>
                !queryRules.includes(rule.queryRule) &&
                !rule.values.length,
        );
        if (hasAttributeFilterError) {
            dispatch(showNotificationByType(NotificationType.ATTRIBUTE_FILTER_VALIDATE_ERROR));
        } else {
            dispatch(getSearchResult(searchText, filteredSearchRules, searchVisibility, searchNodeTypes, rootSearchNodeId));
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.trim();
        setSearchTextHandler(searchValue);
    };

    const handleKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === Keys.ENTER) {
            handleSearch();
        }
    };

    const onSearchVisibilityChange = (value: SearchRequestSearchVisibilityEnum): void => {
        setSearchVisibilityHandler(value);
    };

    const getSearchVisibilityLabel = (value: SearchRequestSearchVisibilityEnum): string => {
        return messages[value] ? intl.formatMessage(messages[value]) : value;
    };

    return (
        <div className={style.searchFieldContainer}>
            <Input
                data-test="search_search-field-input"
                autoFocus
                suffix={<SearchOutlined />}
                onChange={handleChange}
                onKeyPress={handleKeypress}
                style={{ width: 400 }}
                placeholder={intl.formatMessage(messages.placeholder)}
            />

            <Button
                dataTest="search_search-start-button"
                key="ok"
                visualStyle="primary"
                onClick={handleSearch}
                disabled={isLoading}
            >
                {intl.formatMessage(messages.button)}
            </Button>

            {useSelector(hasUserShowDeletedAccess()) && (
                <div className={style.selectContainer}>
                    <Select
                        data-test="search_visibility-field-input"
                        isMultiSelect={false}
                        value={getSearchVisibilityLabel(searchVisibility)}
                        onChange={onSearchVisibilityChange}
                    >
                        {searchVisibilitySearchParamOptions.map((val) => (
                            <Select.Option key={val} value={val} label={getSearchVisibilityLabel(val)} />
                        ))}
                    </Select>
                </div>
            )}
        </div>
    );
};
