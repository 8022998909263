import type { TRootState } from '../../../reducers/root.reducer.types';
import type { TWikiImage } from '../../../models/tab.types';
import type { NodeId } from '../../../serverapi/api';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { wikiUploadImage } from '@/actions/entities/wiki.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { FileUploadDialog } from '../../FileUpload/components/FileUploadDialog.component';
import { v4 as uuid } from 'uuid';

type TImageDialogContainerOwnProps = {
    id: string;
    workspaceId: NodeId;
    onSubmit: (image: TWikiImage) => void;
};

const mapStateToProps = (state: TRootState, ownProps: TImageDialogContainerOwnProps): Partial<any> => {
    const activeTab = TabsSelectors.getActiveTab(state);

    return {
        id: ownProps.id || uuid(),
        formInitData: { src: '', title: '', alt: '' },
        content: activeTab && activeTab.content,
        isWiki: true,
    };
};

const mapDispatchToProps = (dispatch, ownProps: TImageDialogContainerOwnProps) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG_WIKI));
    },
    onSubmit: (image: TWikiImage) => {
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG_WIKI));
        if (ownProps.onSubmit) {
            ownProps.onSubmit(image);
        }
    },
    uploadFile: (file: File, nodeId: NodeId, parentNodeId: NodeId) => {
        dispatch(wikiUploadImage({ file, fileId: nodeId, modelId: parentNodeId }));
    },
});

export const WikiUploadDialogContainer = connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
