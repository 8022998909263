import React, { FC, useCallback, useEffect, useState } from 'react';
import { ExistingDefinitionSelectView } from '../view/ExistingDefinitionSelectView.component';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import theme from '../view/ExistingDefinitionSelectView.scss';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { selectObjectDialogPrepareDataRequest } from '../../../../../actions/selectObjectDefinitionDialog.actions';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useObjectTableData } from '../hooks/useObjectTableData';
import { Icon } from '../../../../UIKit';
import { TreeItemType } from '../../../../Tree/models/tree';
import { moveToDirectAction } from '../../../../../actions/editor.actions';
import { saveObjectDefinition } from '../../../../../actions/entities/objectDefinition.actions';

type TSelectObjectTreeDialogContainerProps = {
    open: boolean;
    instances: ObjectDefinitionImpl[];
    objectDefinition: ObjectDefinitionImpl;
};

export const SelectObjectTreeDialogContainer: FC<TSelectObjectTreeDialogContainerProps> = ({
    open,
    instances,
    objectDefinition,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);
    const [selectedStrategy, setSelectedStrategy] = useState<SelectedStrategy>(SelectedStrategy.save);

    useEffect(() => {
        dispatch(selectObjectDialogPrepareDataRequest(instances));
    }, []);

    const nameRenderer = (obj: ObjectDefinitionImpl) => {
        const isSelected = objectDefinition.nodeId.id === selectedInstanceId;

        return (
            <div className={!isSelected ? theme.name : ''}>
                {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                {obj.name}
            </div>
        );
    };

    const { columns, dataSource, onRow } = useObjectTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );
    const { objectTypeTitle } = useSelector(SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState);

    const handleSelectStrategy = useCallback((value: SelectedStrategy) => {
        setSelectedStrategy(value);
    }, []);

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.CHECK_OBJECT_DIALOG));
    }, []);

    const handleSubmit = () => {
        const selectedInstance = instances.find(({ nodeId: { id } }) => id === selectedInstanceId);

        if (!selectedInstance) return;

        switch (selectedStrategy) {
            case SelectedStrategy.save: {
                dispatch(saveObjectDefinition(objectDefinition.nodeId.serverId, objectDefinition));
                break;
            }
            case SelectedStrategy.showInTree: {
                const { nodeId, type, name } = selectedInstance;
                dispatch(
                    moveToDirectAction({
                        nodeId,
                        name,
                        type: type as TreeItemType,
                        hasChildren: false,
                        countChildren: 0,
                    }),
                );
                break;
            }
            default:
                break;
        }

        dispatch(closeDialog(DialogType.CHECK_OBJECT_DIALOG));
    };

    return (
        <ExistingDefinitionSelectView
            open={open}
            title={intl.formatMessage(messages.selectObject)}
            text={intl.formatMessage(messages.objectHeaderMessage, { objectTypeTitle })}
            onOk={handleSubmit}
            onCancel={onClose}
            tableProps={{
                onRow,
                columns,
                dataSource,
                disabled: selectedStrategy === SelectedStrategy.save,
            }}
            radioProps={{
                selectedStrategy,
                handleSelectStrategy,
                data: [
                    {
                        value: SelectedStrategy.save,
                        text: intl.formatMessage(messages.createNewObject),
                        dataTest: 'select-object_set-new-name-object-radio',
                    },
                    {
                        value: SelectedStrategy.showInTree,
                        text: intl.formatMessage(messages.useSelectedObject),
                        dataTest: 'select-object_use-existing-object-radio',
                    },
                ],
            }}
        />
    );
};
