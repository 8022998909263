import {
    EntityEnum,
    SymbolViewEnum,
    TNavigatorPropertiesSelectorState,
} from './navigatorPropertiesSelectorState.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { NodeTypeEnum } from '@/serverapi/api';

const treeItemTypes = {
    [TreeItemType.EdgeDefinition]: EntityEnum.EDGE,
    [TreeItemType.ObjectDefinition]: EntityEnum.OBJECT,
    [TreeItemType.Model]: EntityEnum.MODEL,
    [TreeItemType.Wiki]: EntityEnum.WIKI,
    [TreeItemType.Matrix]: EntityEnum.MATRIX,
    [TreeItemType.Folder]: EntityEnum.FOLDER,
    [TreeItemType.Repository]: EntityEnum.DB,
    [TreeItemType.Script]: EntityEnum.SCRIPT,
    [TreeItemType.ScriptFolder]: EntityEnum.SCRIPT_FOLDER,
    [TreeItemType.Spreadsheet]: EntityEnum.SPREADSHEET,
    [TreeItemType.Kanban]: EntityEnum.KANBAN,
    [TreeItemType.Dashboard]: EntityEnum.DASHBOARD,
    [TreeItemType.SimulationModeling]: EntityEnum.SIMULATION,
    [TreeItemType.File]: EntityEnum.FILE,
    [TreeItemType.FileFolder]: EntityEnum.FILE_FOLDER,
};

export const tabWithDefaultPropChangeAction = {
    [WorkSpaceTabTypes.WIKI_EDITOR]: EntityEnum.WIKI,
    [WorkSpaceTabTypes.MARTIX_EDITOR]: EntityEnum.MATRIX,
    [WorkSpaceTabTypes.SCRIPT_EDITOR]: EntityEnum.SCRIPT,
    [WorkSpaceTabTypes.EDITOR]: EntityEnum.MODEL,
    [WorkSpaceTabTypes.SPREADSHEET]: EntityEnum.SPREADSHEET,
    [WorkSpaceTabTypes.DASHBOARD]: EntityEnum.DASHBOARD,
    [WorkSpaceTabTypes.SIMULATION_MODELING]: EntityEnum.SIMULATION,
};

export const convert = (item: TreeItemType | NodeTypeEnum): EntityEnum | undefined => treeItemTypes[item];

export const initialNavigatorPropertiesSelectorState: TNavigatorPropertiesSelectorState = {
    nodeId: {
        serverId: '',
        id: '',
        repositoryId: '',
    },
    currentGraphId: undefined,
    cellId: undefined,
    symbolViewMode: SymbolViewEnum.ENTRY_COUNT,
    entityType: undefined,
};
