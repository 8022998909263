import { RESET_SEARCH_DATA, SET_SEARCH_DATA, SET_FILTER_DATA } from '../actionsTypes/search.actionTypes';
import { addIfExist } from '../utils/redux.utils';
import { TSearchState } from './search.reducer.types';

const initial: { [key: string]: TSearchState } = {};

export const SearchReducer = (state = initial, action) => {
    switch (action.type) {
        case SET_SEARCH_DATA: {
            const { id, nodeId, name, type, path, searchResult, isLoading, searchText, foundElementsCount } =
                action.payload;

            if (id) {
                const newState = { ...state };

                newState[id] = {
                    id: addIfExist(name, state[id]?.id || null),
                    nodeId: addIfExist(
                        nodeId,
                        state[id]?.nodeId || {
                            id: '',
                            repositoryId: '',
                            serverId: '',
                        },
                    ),
                    name: addIfExist(name, state[id]?.name || null),
                    type: addIfExist(type, state[id]?.type || null),
                    path: addIfExist(path, state[id]?.path || ''),
                    searchResult: addIfExist(searchResult, state[id]?.searchResult || []),
                    isLoading: addIfExist(isLoading, state[id]?.isLoading || false),
                    searchText: addIfExist(searchText, state[id]?.searchText || ''),
                    filter: [],
                    foundElementsCount: addIfExist(foundElementsCount, state[id]?.foundElementsCount),
                };

                return newState;
            }

            return state;
        }

        case SET_FILTER_DATA: {
            const { id, filter } = action.payload;

            return {
                ...state,
                [id]: { ...state[id], filter },
            };
        }

        case RESET_SEARCH_DATA: {
            const { nodeId } = action.payload;

            const newState = { ...state };
            delete newState[nodeId.id];

            return newState;
        }

        default: {
            return state;
        }
    }
};
