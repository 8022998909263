import React, { FC } from 'react';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import { Table } from 'antd';
import theme from './ExistingDefinitionSelectView.scss';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import { TExistingDefinitionSelectViewProps, TRadioData } from './ExistingDefinitionSelectView.types';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { SelectedStrategy } from '@/models/selectObjectDialog.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

export const ExistingDefinitionSelectView: FC<TExistingDefinitionSelectViewProps> = ({
    open,
    title,
    text,
    radioProps,
    tableProps,
    onOk,
    onCancel,
}) => {
    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.confirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    const radioOptions: TRadioOption<SelectedStrategy>[] = radioProps.data.map((radio: TRadioData) => ({
        value: radio.value,
        dataTest: radio.dataTest,
        title: (
            <>
                {radio.text}
                {radio.warningText && <span style={{ color: 'red' }}>&nbsp; ({radio.warningText})</span>}
            </>
        ),
    }));

    return (
        <Dialog open={open} onOk={onOk} onCancel={onCancel} title={title} width="620px" footer={footer}>
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>{text}</div>
                <RadioGroup<SelectedStrategy>
                    options={radioOptions}
                    onChange={radioProps.handleSelectStrategy}
                    value={radioProps.selectedStrategy}
                    margin={8}
                />
                {tableProps.dataSource && (
                    <div className={tableProps.disabled ? theme.disabledContainer : ''}>
                        <div className={theme.tableContainer}>
                            <Table
                                className={theme.table}
                                rowKey={(record) => record.key}
                                onRow={tableProps.onRow}
                                dataSource={tableProps.dataSource}
                                bordered
                                pagination={false}
                                size="middle"
                                columns={tableProps.columns}
                                scroll={{
                                    y: 'max-content',
                                    x: 'max-content',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    );
};
