import React, { FC, useCallback, useState, useEffect } from 'react';
import { ExistingDefinitionSelectView } from '../view/ExistingDefinitionSelectView.component';
import { useIntl } from 'react-intl';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { EdgeDefinitionNode } from '../../../../../serverapi/api';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import { Icon } from '../../../../UIKit';
import theme from '../view/ExistingDefinitionSelectView.scss';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { setFocusAndStartEditAction } from '../../../../../actions/editor.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { SelectEdgeDefinitionDialogSelectors } from '../../../../../selectors/selectEdgeDefinitionDialog.selectors';
import {
    selectEdgeDialogPrepareDataRequest,
    selectEdgeDialogSubmit,
    selectEdgeDialogSubmitFromMatrix,
} from '../../../../../actions/selectEdgeDefinitionDialog.actions';
import { useEdgeTableData } from '../hooks/useEdgeTableData';

type TSelectEdgeDefinitionDialogContainerProps = {
    instances: EdgeDefinitionNode[];
    cellId: string;
    sourceObjectDefinitionId: string;
    targetObjectDefinitionId: string;
    isCreatingFromMatrix?: boolean;
    open: boolean;
};

export const SelectEdgeDefinitionDialogContainer: FC<TSelectEdgeDefinitionDialogContainerProps> = ({
    instances,
    cellId,
    sourceObjectDefinitionId,
    targetObjectDefinitionId,
    isCreatingFromMatrix,
    open,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);
    const [selectedStrategy, setSelectedStrategy] = useState<SelectedStrategy>(SelectedStrategy.createNew);

    const { sourceObjectName, targetObjectName } = useSelector(
        SelectEdgeDefinitionDialogSelectors.selectEdgeDefinitionDialogState,
    );

    useEffect(() => {
        dispatch(selectEdgeDialogPrepareDataRequest(instances, sourceObjectDefinitionId, targetObjectDefinitionId));
    }, []);

    const nameRenderer = useCallback(
        (edgeDefinition: EdgeDefinitionNode) => {
            const isSelected = edgeDefinition.nodeId.id === selectedInstanceId;

            return (
                <div className={!isSelected ? theme.name : ''}>
                    {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                    {edgeDefinition.name || ''}
                </div>
            );
        },
        [selectedInstanceId],
    );

    const { columns, dataSource, onRow } = useEdgeTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );

    const handleSubmit = () => {
        const selectedInstance = instances.find((instance) => instance.nodeId.id === selectedInstanceId);

        if (!selectedInstance) return;

        if (isCreatingFromMatrix) {
            dispatch(selectEdgeDialogSubmitFromMatrix(selectedStrategy, selectedInstance));
        } else {
            dispatch(selectEdgeDialogSubmit(selectedStrategy, selectedInstance, cellId));
        }
    };

    const onClose = useCallback(() => {
        dispatch(setFocusAndStartEditAction(cellId));
        dispatch(closeDialog(DialogType.SELECT_EDGE_DIALOG));
    }, [cellId]);

    const handleSelectStrategy = useCallback((value: SelectedStrategy) => {
        setSelectedStrategy(value);
    }, []);

    return (
        <ExistingDefinitionSelectView
            open={open}
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectEdge)}
            text={intl.formatMessage(messages.edgeHeaderMessage, {
                sourceObjectName,
                targetObjectName,
            })}
            tableProps={{
                disabled: selectedStrategy === SelectedStrategy.createNew,
                dataSource,
                columns,
                onRow,
            }}
            radioProps={{
                handleSelectStrategy,
                selectedStrategy,
                data: [
                    {
                        value: SelectedStrategy.createNew,
                        text: intl.formatMessage(messages.createNewEdgeDefinition),
                        dataTest: 'select-object_set-new-name-object-radio',
                    },
                    {
                        value: SelectedStrategy.useExisting,
                        text: intl.formatMessage(messages.useSelectedEdgeDefinition),
                        dataTest: 'select-object_use-existing-object-radio',
                    },
                ],
            }}
        />
    );
};
