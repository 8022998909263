import type { TreeNode, TTreeEntityState } from '../models/tree.types';
import type { TRootState } from '../reducers/root.reducer.types';
import type { NodeId, RepositoryNode } from '../serverapi/api';
import { createSelector } from 'reselect';
import { compareTree } from '../models/tree';
import { TreeItemType } from '../modules/Tree/models/tree';
import { sortBy } from 'lodash-es';
import { ServerSelectors } from './entities/server.selectors';
import { RootNodeId } from '../serverapi/api';
import { ObjectTypeSelectors } from './objectType.selectors';
import { ExpandStatus, TTreeState } from '../reducers/tree.reducer.types';
import { TServerEntity } from '@/models/entities.types';
import { getNodeName } from '../modules/Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { ExpandedStatusSelector } from './expandedStatus.selectors ';

const getRootState = (state: TRootState) => state;
const getState = (state: TRootState) => state.tree;

const isDeletedNode = (node: TreeNode) => !node.deleted;
const isHiddenNode = (state: TRootState) => (node: TreeNode) => {
    if (node.type !== TreeItemType.ObjectDefinition || !node.objectTypeId) {
        return true;
    }

    const presetId: string = TreeSelectors.presetById(node.nodeId)(state);
    const objectType = ObjectTypeSelectors.byId({
        objectTypeId: node.objectTypeId,
        presetId,
        serverId: node.nodeId.serverId,
    })(state);

    return !objectType?.hideInNavigator;
};

export const getAllTreeItems = (serverId: string, repositoryId: string) =>
    createSelector<TRootState, TTreeState, { [id: string]: TTreeEntityState }>(
        getState,
        (state) => state.byServerId[serverId]?.byRepositoryId[repositoryId]?.byId || {},
    );

const getFilteredTreeItems = (
    items: { [id: string]: TTreeEntityState },
    filterFunctions: ((item: TreeNode) => boolean)[],
): { [id: string]: TTreeEntityState } =>
    Object.keys(items).reduce((acc, nodeId) => {
        if (filterFunctions.every((filterFn) => filterFn(items[nodeId]))) {
            acc[nodeId] = items[nodeId];
        }

        return acc;
    }, {});

function filterTreeNodes(
    treeNodes: TreeNode[] | undefined,
    filterFn: (node: TreeNode) => boolean,
    childrenIdOrder: string[] = [],
): TreeNode[] {
    if (treeNodes) {
        const filtredTreeNodes: TreeNode[] = treeNodes
            .reduce((newTreeNodes, treeNode) => {
                if (filterFn(treeNode)) {
                    if (treeNode.children?.length) {
                        const children = filterTreeNodes(treeNode.children, filterFn, treeNode.childrenIdOrder);
                        newTreeNodes.push({
                            ...treeNode,
                            children,
                        });
                    } else {
                        newTreeNodes.push(treeNode);
                    }
                }

                return newTreeNodes;
            }, [] as TreeNode[])
            .sort((n1, n2) => compareTree(n1, n2, childrenIdOrder));

        return filtredTreeNodes;
    }

    return [];
}

export const getTreeItems = (serverId: string, repositoryId: string) =>
    createSelector<TRootState, { [id: string]: TTreeEntityState }, { [id: string]: TTreeEntityState }>(
        getAllTreeItems(serverId, repositoryId),
        (items: { [id: string]: TTreeEntityState }) => getFilteredTreeItems(items, [isDeletedNode]),
    );

export const getTreeItemsExceptIds = (serverId: string, repositoryId: string, exceptIds: string[]) =>
    createSelector<TRootState, { [id: string]: TTreeEntityState }, { [id: string]: TTreeEntityState }>(
        getAllTreeItems(serverId, repositoryId),
        (items: { [id: string]: TTreeEntityState }) =>
            getFilteredTreeItems(items, [isDeletedNode, (node: TreeNode) => !exceptIds.includes(node.nodeId.id)]),
    );

const getNestedChildren = (
    items: { [id: string]: TTreeEntityState },
    id: string,
    children: TreeNode[] = [],
): TreeNode[] => {
    if (!items[id]) {
        return children;
    }
    const { childrenIds = [] } = items[id];

    return childrenIds.reduce((acc: TreeNode[], itemId: string) => {
        const nestedChildren = getNestedChildren(items, itemId, children);

        return [
            ...acc,
            {
                ...items[itemId],
                children: nestedChildren,
            },
        ];
    }, children);
};

export function filterTreeExcludeTypes(data: TreeNode[] | undefined, excludeTypes: TreeItemType[]): TreeNode[] {
    return filterTreeNodes(data, (node: TreeNode) => !excludeTypes.includes(node.type));
}

export function filterTreeIncludeTypes(data: TreeNode[] | undefined, includeTypes: TreeItemType[]): TreeNode[] {
    return filterTreeNodes(data, (node: TreeNode) => includeTypes.includes(node.type));
}

export function filterTreeExcludeNodes(data: TreeNode[] | undefined, excludeNodeIds: NodeId[]): TreeNode[] {
    return filterTreeNodes(
        data,
        (node: TreeNode) => !excludeNodeIds.some((excludeNodeId) => compareNodeIds(excludeNodeId, node.nodeId)),
    );
}

export const getRepositories = (serverId: string) =>
    createSelector(getState, (state) => {
        const repoList: Array<TreeNode> = [];
        Object.values(state.byServerId[serverId].byRepositoryId).filter((i: any) =>
            Object.values(i.byId).map((node: TreeNode) => node.type === TreeItemType.Repository && repoList.push(node)),
        );

        return repoList;
    });

export const getFileFolderTree = createSelector(getRootState, (state) => {
    const data = TreeSelectors.treeStructure(state);
    const filesTree = data[0]?.children?.filter((treeNode) => {
        return treeNode.nodeId.id === RootNodeId.FILE_FOLDER_ROOT_ID;
    });

    return filesTree as TreeNode[];
});

export const getScrolledNode = createSelector(getState, (state) => state.scrolledNodeId);

export const getFiltersApplied = createSelector(getState, (state) => state.appliedFilters);

export const isNodeRenaming = (nodeId: NodeId) =>
    createSelector(getRootState, (state) => compareNodeIds(nodeId, state.renameNode.nodeId));

export const getDraggedIdItem = createSelector(getState, (state) => state.draggedId);

export const getShowDeletedObjectsFilter = createSelector(getState, (state) => state.showDeletedObjectsFilter);

export const getNodeWithChildren = (parent: TTreeEntityState = { nodeId: {} } as TTreeEntityState) =>
    createSelector<TRootState, { [id: string]: TTreeEntityState }, TTreeEntityState>(
        getTreeItems(parent.nodeId.serverId, parent.nodeId.repositoryId),
        (items: { [id: string]: TTreeEntityState }) => {
            return (
                items && {
                    ...parent,
                    children: getNestedChildren(items, parent.nodeId.id),
                }
            );
        },
    );

export const getNodeWithChildrenById = (nodeId: NodeId) =>
    createSelector(TreeSelectors.itemById(nodeId), (res: TTreeEntityState) => getNodeWithChildren(res));

export const sortChildren = (children: TreeNode[]) =>
    sortBy(children, [
        (el: TreeNode) => {
            switch (el.type) {
                case TreeItemType.Server:
                    return 0;
                case TreeItemType.Repository:
                    return 1;
                case TreeItemType.Folder:
                    return 2;
                case TreeItemType.ScriptFolder:
                    return 3;
                case TreeItemType.Model:
                    return 4;
                case TreeItemType.ObjectDefinition:
                    return 5;
                case TreeItemType.Script:
                    return 6;
                case TreeItemType.Wiki:
                    return 7;
                case TreeItemType.Matrix:
                    return 8;
                case TreeItemType.AdminTool:
                    return 9;
                case TreeItemType.SimulationModeling:
                    return 10;
                default:
                    return undefined;
            }
        },
        (el: TreeNode) => (el.name || '').toLowerCase(),
    ]);

export const treeNestedChildrenMap = (items: { [id: string]: TTreeEntityState }, id: string): TreeNode[] => {
    if (!items[id]) {
        return [];
    }

    const item: TTreeEntityState = items[id];

    const result: TreeNode = {
        nodeId: item.nodeId,
        name: item.name,
        multilingualName: item.multilingualName,
        parentNodeId: item.parentNodeId,
        countChildren: item.childrenIds?.length,
        hasChildren: item.hasChildren || (item.childrenIds && item.childrenIds.length > 0),
        type: item.type,
        modelTypeId: item.modelTypeId,
        objectTypeId: item.objectTypeId,
        edgeTypeId: item.edgeTypeId,
        folderType: item.folderType,
        idSymbol: item.idSymbol,
        presetId: item.presetId,
        fileFolderType: item.fileFolderType,
        extension: item.extension,
        childrenIdOrder: item.childrenIdOrder,
        ...(item.language && { language: item.language }),
        deleted: item.deleted,
        deletedBy: item.deletedBy,
        deletedAt: item.deletedAt,
        userEditDisabled: item.userEditDisabled,
        scriptEngineEditDisabled: item.scriptEngineEditDisabled,
        children: sortChildren(
            item.childrenIds.reduce((treeNodes: TreeNode[], itemId: string) => {
                if (items[itemId]) {
                    const treeNode: TreeNode | undefined = treeNestedChildrenMap(items, itemId)[0];
                    if (treeNode) {
                        treeNodes.push(treeNode);
                    }
                }

                return treeNodes;
            }, []),
        ),
    };

    return [result];
};

const mapServerTreeStructure =
    (state: TRootState) =>
    (server: any): TreeNode => {
        const { id: serverId, name, multilingualName, countChildren } = server;
        const serverState = state.tree.byServerId[serverId];
        const childrenIds = (serverState && Object.keys(serverState.byRepositoryId)) || [];
        const children = sortChildren(
            childrenIds.reduce((treeNodes: TreeNode[], repositoryId: string) => {
                const items: { [id: string]: TTreeEntityState } = getAllTreeItems(serverId, repositoryId)(state);

                if (items[repositoryId]) {
                    const repositoryNode: TreeNode | undefined = treeNestedChildrenMap(items, repositoryId)[0];
                    if (repositoryNode) {
                        treeNodes.push(repositoryNode);
                    }
                }

                return treeNodes;
            }, []),
        );

        return {
            nodeId: {
                id: serverId,
                serverId,
                repositoryId: serverId,
            },
            name,
            multilingualName,
            type: TreeItemType.Server,
            hasChildren: childrenIds.length > 0,
            parentNodeId: undefined,
            countChildren,
            children,
        };
    };

export const walkTree = (nodeId: NodeId, state: TRootState, treeName: string): NodeId[] => {
    const treeEntity: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);

    if (
        treeEntity?.childrenIds?.length &&
        ExpandedStatusSelector.expandStatus(treeName, nodeId)(state) === ExpandStatus.OPEN
    ) {
        const children = treeEntity.childrenIds
            .map((id) => walkTree({ ...treeEntity.nodeId, id }, state, treeName))
            .flat();

        return [nodeId, ...children];
    }

    return [];
};

export namespace TreeSelectors {
    export const filters = (state: TRootState) => state.tree.appliedFilters;

    export const connected = createSelector(ServerSelectors.connected, (connectedServers) => [...connectedServers]);

    export const disconnected = createSelector(ServerSelectors.disconnected, (disconnectedServers) => [
        ...disconnectedServers,
    ]);

    export const pending = createSelector(ServerSelectors.pending, (pendingServers) => [...pendingServers]);

    export const fullTreeStructure = createSelector<TRootState, TServerEntity[], TTreeState, TreeNode[]>(
        ServerSelectors.serverList,
        getState,
        (servers, state) => servers.map(mapServerTreeStructure({ tree: state } as TRootState)),
    );

    export const isEmptyTree = createSelector<TRootState, TreeNode[], boolean>(
        fullTreeStructure,
        (treeData) => treeData.length === 0,
    );

    export const treeStructure = createSelector<TRootState, TreeNode[], TreeNode[]>(
        fullTreeStructure,
        (nodes: TreeNode[]) => filterTreeNodes(nodes, isDeletedNode),
    );

    export const treeStructureConnected = createSelector(treeStructure, connected, (treeStructure, connected) =>
        treeStructure.filter((server) => connected.includes(server.nodeId.serverId)),
    );

    export const itemById = (nodeId: NodeId = {} as NodeId) => {
        return createSelector<TRootState, { [id: string]: TTreeEntityState }, TTreeEntityState | undefined>(
            getAllTreeItems(nodeId.serverId, nodeId.repositoryId),
            (byId: { [id: string]: TTreeEntityState }) => (!byId[nodeId.id]?.deleted ? byId[nodeId.id] : undefined),
        );
    };

    export const unfilteredItemById = (nodeId: NodeId = {} as NodeId) => {
        return createSelector<TRootState, TTreeState, TTreeEntityState>(
            getState,
            (state) => state.byServerId[nodeId.serverId]?.byRepositoryId[nodeId.repositoryId]?.byId[nodeId.id],
        );
    };

    export const openChildrenRefreshNodeList = (nodeId: NodeId, treeName: string) =>
        createSelector<TRootState, TRootState, NodeId[]>(getRootState, (state) => walkTree(nodeId, state, treeName));

    export const presetById = (nodeId: NodeId = {} as NodeId) => {
        return createSelector<TRootState, TTreeState, string>(getState, (state) => {
            const { repositoryId, serverId } = nodeId;

            return (
                (
                    state.byServerId[serverId]?.byRepositoryId[repositoryId]?.byId[repositoryId] as
                        | RepositoryNode
                        | undefined
                )?.presetId || ''
            );
        });
    };

    export const getNodeNameById = (nodeId: NodeId = {} as NodeId) => {
        return createSelector<TRootState, TTreeState, string>(getState, (state) => {
            const { id, repositoryId, serverId } = nodeId;
            return state.byServerId[serverId]?.byRepositoryId[repositoryId]?.byId[id]?.name || '';
        });
    };

    export const nodePath = (nodeId: NodeId = {} as NodeId) => {
        return createSelector<TRootState, { [id: string]: TServerEntity }, { [id: string]: TTreeEntityState }, string>(
            ServerSelectors.serverById,
            getTreeItems(nodeId.serverId, nodeId.repositoryId),
            (servers: { [id: string]: TServerEntity }, state: { [id: string]: TTreeEntityState }) => {
                const path: Array<string> = [];
                let { id } = nodeId;
                while (state[id]) {
                    path.push(getNodeName(state[id]));
                    if (state[id].parentNodeId) {
                        id = state[id].parentNodeId!.id;
                    } else {
                        break;
                    }
                }

                return `${servers[nodeId.serverId]?.name}/${path.reverse().join('/')}`;
            },
        );
    };

    export const isItemOnOpenTree = (nodeId: NodeId, treeName: string) => {
        return createSelector(
            itemById(nodeId),
            openChildrenRefreshNodeList({ ...nodeId, id: nodeId.repositoryId }, treeName),
            (itemNode, openItems: NodeId[]) => {
                return itemNode && openItems.some((item) => item.id === itemNode.parentNodeId?.id);
            },
        );
    };

    export const extraButtonsTreeNodes = createSelector<TRootState, TreeNode[], TreeNode[]>(
        treeStructure,
        (treeNodes) => {
            const excludeTypes = [
                TreeItemType.AdminTool,
                TreeItemType.FileFolder,
                TreeItemType.ScriptFolder,
                TreeItemType.Script,
                TreeItemType.ObjectDefinition,
                TreeItemType.Default,
                TreeItemType.File,
            ];

            return filterTreeExcludeTypes(treeNodes, excludeTypes);
        },
    );

    export const filteredTreeNodes = createSelector<TRootState, TRootState, TreeNode[], TreeNode[]>(
        getRootState,
        fullTreeStructure,
        (state, treeNodes: TreeNode[]) => filterTreeExcludeTypes(treeNodes, state.tree.appliedFilters),
    );

    export const treeNodesWithoutHidden = createSelector<TRootState, TRootState, TreeNode[], TreeNode[]>(
        getRootState,
        filteredTreeNodes,
        (state, treeNodes: TreeNode[]) => filterTreeNodes(treeNodes, isHiddenNode(state)),
    );

    export const walkAllTree = (nodeId: NodeId, state: TRootState, treeName: string): NodeId[] => {
        const treeEntity: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);

        if (treeEntity?.childrenIds?.length) {
            const children = treeEntity.childrenIds
                .map((id) => walkAllTree({ ...treeEntity.nodeId, id }, state, treeName))
                .flat();

            return [nodeId, ...children];
        }

        return [];
    };

    export const isParent = (nodeId: NodeId, possibleParentNodeId: NodeId, rootState: TRootState): boolean => {
        if (nodeId.repositoryId !== possibleParentNodeId.repositoryId) return false;

        const treeEntity: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(rootState);

        if (!treeEntity || !treeEntity.parentNodeId) {
            return false;
        }

        if (treeEntity.parentNodeId.id === possibleParentNodeId.id) {
            return true;
        }

        return isParent(treeEntity.parentNodeId, possibleParentNodeId, rootState);
    };
}
