import type { TRichTextEditorProps } from './RichTextEditor.types';
import type { WikiContent } from '@/serverapi/api';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { EditorContent, useEditor, Editor, Content } from '@tiptap/react';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from './extensions/text-style';
import { LineHeight } from './extensions/line-height';
import { Indent } from './extensions/indent';
import { Link } from './extensions/link';
import { Image } from './extensions/image';
import { Table } from './extensions/table';
import { TableCell } from './extensions/table-cell';
import { TableHeader } from './extensions/table-header';
import { Comment } from './extensions/comment';
import { Code } from './extensions/code';
import { TableOfContents } from './extensions/table-of-contents';
import { CodeBlock } from './extensions/code-block';
import { Object } from './extensions/object';
import { DropHandler } from './extensions/drop-handler';
import { PasteHandler } from './extensions/paste-handler';
import Document from '@tiptap/extension-document';
import FontFamily from '@tiptap/extension-font-family';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import TableRow from '@tiptap/extension-table-row';
import Heading from '@tiptap/extension-heading';
import Blockquote from '@tiptap/extension-blockquote';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import { getStateObserver } from '../common/sharedState.class';
import { ContextMenu } from './components/ContextMenu.component';
import theme from './RichTextEditor.component.scss';
import { IMAGE_NODE_NAME } from './extensions/constants';
import { getFullHierarchicalIndexes, getReducedLevel } from './extensions/table-of-contents.utils';
import { EMPTY_WIKI_CONTENT } from '@/modules/Wiki/WikiEditor.utils';
import messages from './RichTextEditor.messages';
import { isImageFile } from '@/utils/files.utils';
import { imageMimeTypes } from '@/utils/image.utils';
import { DEFAULT_LINE_HEIGHT } from '../Toolbar/controls/Controls.constants';

type TClipboardData = { types: string[]; data: Blob[] };

const SAVE_DEBOUNCE_TIME = 1000;
const importState = (jsonState: WikiContent | undefined): Content => {
    try {
        return jsonState || EMPTY_WIKI_CONTENT;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Wiki import error', e);

        return EMPTY_WIKI_CONTENT;
    }
};

const getStateForExport = (editor: Editor): WikiContent => {
    try {
        return editor.getJSON();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Wiki export error', e);

        return EMPTY_WIKI_CONTENT;
    }
};

const extensions = [
    Color,
    TextStyle,
    LineHeight.configure({ types: ['paragraph'], defaultLineHeight: DEFAULT_LINE_HEIGHT }),
    Indent.configure({ types: ['paragraph', IMAGE_NODE_NAME] }),
    Document,
    Paragraph.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    Text,
    TextAlign.configure({ types: ['paragraph', IMAGE_NODE_NAME, 'listItem', 'heading'] }),
    FontFamily,
    Bold,
    Italic,
    Underline,
    Strike,
    Superscript,
    Subscript,
    Code,
    BulletList.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    OrderedList.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    ListItem,
    TaskList.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    TaskItem.configure({ nested: true }),
    Image.configure({ inline: false, HTMLAttributes: { class: 'wiki-block' } }),
    Table.configure({ resizable: true, allowTableNodeSelection: true, HTMLAttributes: { class: 'wiki-block' } }),
    TableRow,
    TableHeader,
    TableCell,
    Heading.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    CodeBlock.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    Blockquote.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    Gapcursor,
    HardBreak,
    History.configure({ depth: 1000 }),
];

export const RichTextEditor: FC<TRichTextEditorProps> = ({
    id,
    value,
    zoomLevel = 100,
    onChange,
    onRendered,
    handlers,
    CommentPopoverContent,
    modelId,
}) => {
    const [editorState] = useState<Content>(importState(value));
    const { readFromClipboard, copyToClipboard, openComment, openInternalLink, uploadImage, dropNode } = handlers;
    const intl = useIntl();

    const shareEditorState = (editor: Editor) => {
        const stateObserver = getStateObserver(id);
        stateObserver.share(editor);
    };

    const onEditorStateChange = useCallback(({ editor }) => {
        shareEditorState(editor);
        // TODO вызывать только на update ?
        exportState(editor);
    }, []);

    const isSupportComments = !!CommentPopoverContent;
    const isSupportDrop = !!uploadImage && !!dropNode;
    const isSupportPaste = !!uploadImage;

    const onLinkClick = useCallback((link, href: string) => {
        if (link && href) {
            window.open(href, '_blank', 'noopener');

            return true;
        }

        return false;
    }, []);

    const onObjectClick = useCallback((objectId: string) => {
        openInternalLink(objectId);
    }, []);

    const handleDrop = useCallback((pos: number, editor: Editor, event: DragEvent) => {
        try {
            const kind = event.dataTransfer?.items?.[0]?.kind;

            if (!kind) {
                return;
            }

            // Код d-n-d
            event.preventDefault();

            if (kind === 'string') {
                const nodeId = JSON.parse(event.dataTransfer?.getData('nodeId') || '');

                dropNode(pos, nodeId);
            }

            if (kind === 'file') {
                const file = event.dataTransfer?.items[0]?.getAsFile();

                if (!file || file.size === 0) {
                    return;
                }

                const id = uuid();

                if (isImageFile(file)) {
                    const { src } = uploadImage(file, id);

                    editor.commands.insertContentAt(
                        pos,
                        { type: IMAGE_NODE_NAME, attrs: { id, src } },
                        { updateSelection: false },
                    );
                }
            }
        } catch (e) {
            return;
        }
    }, []);

    const handlePaste = useCallback(async (pos: number, editor: Editor, event: ClipboardEvent) => {
        try {
            const clipboardItems: ClipboardItem[] = await navigator.clipboard.read();
            const clipboardData: TClipboardData = { types: clipboardItems[0].types as string[], data: [] };

            for (const type of clipboardItems[0].types) {
                const blob = await clipboardItems[0].getType(type);

                clipboardData.data.push(blob);
            }
            const mimeType = clipboardData.types[0];

            if (!mimeType) {
                return;
            }

            // Код вставки
            if (mimeType === imageMimeTypes.png) {
                const id = uuid();
                const name = `${id}.png`;

                const { data } = clipboardData;
                const file = new File([data[0]], name);
                const { src } = uploadImage(file, id);

                editor.commands.insertContentAt(
                    pos,
                    { type: IMAGE_NODE_NAME, attrs: { id, src } },
                    { updateSelection: false },
                );
            }
        } catch (e) {
            return;
        }
    }, []);

    const editor: Editor | null = useEditor({
        extensions: [
            ...extensions,
            ...(isSupportComments ? [Comment.configure({ commentTooltipComponent: CommentPopoverContent })] : []),
            DropHandler.configure({ onDrop: isSupportDrop ? handleDrop : undefined }),
            PasteHandler.configure({ onPaste: isSupportPaste ? handlePaste : undefined }),
            Link.configure({ autolink: false, openOnClick: false, onLinkClick }),
            TableOfContents.configure({
                emptyContentTitle: intl.formatMessage(messages.emptyTocContentTitle),
                getIndex: getFullHierarchicalIndexes,
                getLevel: getReducedLevel,
            }),
            Object.configure({ onObjectClick }),
        ],
        // TODO
        // при наличии неизвестных имен узлов импорт всего документа фейлится и заменяется на пустой
        // например, в мок данных можно изменить { type: "extendedImage", ... } на { type: "image", ... }
        // необходимо учесть это при настройке обратной совместимости
        // дополнить story всеми типами node и mark, чтобы зафиксировать типы данных
        content: editorState,
        onTransaction: onEditorStateChange,
    });

    const handleContainerClick = () => editor?.commands.focus();

    const exportState = debounce((editor: Editor) => {
        const source = getStateForExport(editor);

        onChange(source);
    }, SAVE_DEBOUNCE_TIME);

    useEffect(() => {
        if (!editor) {
            return;
        }

        shareEditorState(editor);
        onRendered?.(editor);
    }, [editor]);

    return (
        <div
            style={{ transform: `scale(${zoomLevel / 100})` }}
            className={theme.editorContainer}
            onClick={handleContainerClick}
            data-test="wiki-editor_container"
        >
            <ContextMenu
                editor={editor}
                // TODO вынести обращение к modelId во вне редактора
                modelId={modelId}
                readFromClipboard={readFromClipboard}
                copyToClipboard={copyToClipboard}
                openComment={openComment}
            >
                <EditorContent editor={editor} className={theme.editor} />
            </ContextMenu>
        </div>
    );
};
