import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { NodeId, WikiContent } from '@/serverapi/api';
import { isUndefined } from 'lodash-es';

export const WIKI_CONTENT_PROP_NAME = 'content';

export const EMPTY_WIKI_CONTENT = { content: [], type: 'doc' };

export const getEditorData = (wikiNode?: IWikiNode): string | WikiContent | undefined => {
    return wikiNode?.[WIKI_CONTENT_PROP_NAME] || wikiNode?.source;
};

export const isNewEditorData = (wikiNode?: IWikiNode): boolean => {
    return !isUndefined(wikiNode?.[WIKI_CONTENT_PROP_NAME]);
};

export const getWikiImageSrc = (fileId: NodeId, modelId: NodeId, baseUrl: string) =>
    `${baseUrl}/${fileId.repositoryId}/${fileId.id}/`;
