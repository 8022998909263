import { combineReducers, Reducer } from 'redux';
import { edgeTypeGroupReducer } from './edgeTypeGroup.reducer';
import { mainMenuReducer } from './mainMenu.reducer';
import { tabsReducer } from './tabs.reducer';
import { canvasSettingsReducer } from './canvas-settings.reducer';
import { navigatorReducer } from './navigator.reducer';
import { localeReducer } from './locale.reducer';
import { generalMenuReducer } from './generalMenu.reduces';
import { navigatorPanelReducer } from './navigatorPanel.reducer';
import { authorizationReducer } from './authorization.reducer';
import { dialogsReducer } from './dialogs.reducer';
import { treeReducer } from './tree.reducer';
import { ModelDialogReducer } from './modelDialog.reducer';
import { navigatorPropertiesReducer } from './navigatorProperties.reducer';
import { databaseDialogReducer } from './databaseDialog.reducer';
import { ObjectDefinitionDialogReducer } from './objectDefinitionDialog.reducer';
import { folderDialogReducer } from './folderDialog.reducer';
import { app } from './app.reducer';
import { instancePermissionsReducer } from './instancePermissions.reducer';
import { principalsReducer } from './principals.reducer';
import { modelTypeReducer } from './modelType.reducer';
import { objectTypeReducer } from './objectType.reducer';
import { notificationReducer } from './notification.reducer';
import { recentReducer } from './recent.reducer';
import { favoritesReducer } from './favorites.reducer';
import { ImportDialogReducer } from './importDialog.reducer';
import { modelReducer } from './entities/model.reducer';
import { objectDefinitionReducer } from './entities/objectDefinition.reducer';
import { scriptReducer } from './entities/script.reducer';
import { wikiReducer } from './entities/wiki.reducer';
import { treeNodeTypeReducer } from './treeNodeType.reducer';
import { commentsReducer } from './comments.reducer';
import { symbolReducer } from './symbol.reducer';
import { adminToolReducer } from './admintool/admintool.reducer';
import { licensesReducer } from './admintool/licenses.reducer';
import { usersReducer } from './users.reducer';
import { groupsReducer } from './groups.reducer';
import { accessesReducer } from './accessPermissions.reducer';
import { databaseAccessReducer } from './databaseAccess.reducer';
import { StatusBarReducer } from './statusBar.reducer';
import { presetReducer } from './preset.reducer';
import { ScriptExecuteDialogReducer } from './scriptExecuteDialog.reducer';
import { scriptExecutionReducer } from './entities/scriptExecution.reducer';
import { auditReducer } from './audit.reducer';
import { edgeTypeReducer } from './edgeType.reducer';
import { attributeTypeReducer } from './attributeType.reducer';
import { modelTypeGroupReducer } from './modelTypeGroup.reducer';
import { objectTypeGroupReducer } from './objectTypeGroup.reducer';
import { attributeTypeGroupReducer } from './attributeTypeGroup.reducer';
import { symbolEditorReducer } from './symbol/symbolEditor.reducer';
import { copyReducer } from './copy.reducer';
import { monitoringReducer } from './monitoring.reducer';
import { SearchReducer } from './search.reducer';
import { simulationReducer } from './entities/simulation.reducer';
import { importReducer } from './import.reducer';
import { SessionsReducer } from './sessions.reducer';
import { userProfileReducer } from './userProfile.reducer';
import { presetProfileReducer } from './presetProfile.reducer';
import { scriptSchedulerReducer } from './scriptScheduler.reducer';
import { aclReducer } from './acl.reducer';
import { serverProfileReducer } from './serverProfile.reducer';
import { principalServerProfileReducer } from './principalServerProfile.reducer';
import { presetSettingsModelTypeReducer } from './presetSettings/presetSettingsModelType.reducer';
import { BATCH_ACTIONS, CLEAR_STORE } from '../actionsTypes/rootReducer.actionTypes';
import { deletedObjectTypeGroupReducer } from './deletedObjectTypeGroup.reducer';
import { deletedEdgeTypeGroupReducer } from './deletedEdgeTypeGroup.reducer';
import { deletedModelTypeGroupReducer } from './deletedModelTypeGroup.reducer';
import { entitiesReducer } from './entities/entities.reducer';
import { editModelTypeWorkspaceTabReducer } from './workspaceTabData/editModelTypeWorkspaceTab/editModelTypeWorkspaceTab.reducers';
import { importArisReducer } from './importAris.reducer';
import { allPermissionsReducer } from './allPermissions.reducer';
import { spreadsheetReducer } from './entities/spreadsheet.reducer';
import { folderTypeReducer } from './folderType.reducer';
import { folderTypeEditorReducer } from './folderTypeEditor.reducer';
import { statisticsReducer } from './statistics.reducer';
import { presetIconReducer } from './presetSettings/presetIcon.reducer';
import { presetImageGroupReducer } from './presetSettings/presetImageGroup.reducer';
import { deletedPresetImageGroupReducer } from './presetSettings/deletedPresetImageGroup.reducer';
import { editKanbanModelTypeWorkspaceTabReducer } from './workspaceTabData/editKanbanModelTypeWorkspaceTab/editKanbanModelTypeWorkspaceTab.reducers';
import { presetSettingsKanbanModelTypeReducer } from './presetSettings/presetSettingsKanbanModelType.reducer';
import { editKanbanCardTypeWorkspaceTabReducer } from './workspaceTabData/editKanbanCardTypeWorkspaceTab/editKanbanCardTypeWorkspaceTab.reducers';
import { presetSettingsKanbanCardTypeReducer } from './presetSettings/presetSettingsKanbanCardType.reducer';
import { eventsAccessReducer } from './eventsAccess.reducer';
import { kanbanModelTypeReducer } from './kanbanModelType.reducer';
import { kanbanReducer } from './entities/kanban.reducer';
import { kanbanCardTypeReducer } from './kanbanCardType.reducer';
import { edgeDefinitionReducer } from './entities/edgeDefinition.reducer';
import { changeEntityTypeReducer } from './changeEntityType.reducer';
import { editorReducer } from './editor.reducer';
import { modelEventsReducer } from './modelEvents.reducer';
import { nodesReducer } from './nodes.reducer';
import { fetchedScriptsReducer } from './fetchedScripts.reducer';
import { externalSessionsReducer } from './externalSession.reducer';
import { floatingAttributesPanelReducer } from '../modules/FloatingAttributes/reducers/FloatingAttributes.reducer';
import { systemPropertiesReducer } from './systemProperties.reducer';
import { scriptContextReducer } from './scriptContext.reducer';
import { selectObjectDefinitionDialogReducer } from './selectObjectDefinitionDialog.reducer';
import { selectEdgeDefinitionDialogReducer } from './selectEdgeDefinitionDialog.reducer';
import { dashboardReducer } from './entities/dashboard.reducer';
import { approvalReducer } from './approval.reducer';
import { navigatorTreeSearchReducer } from './navigatorTreeSearch.reducer';
import { autoSaveModelIntervalIdsReducer } from './autoSaveModelIntervalIds.reducer';
import { subscribedNodesIdsReducer } from './subscribedNodesIds.reducer';
import { contextMenuReducer } from './contextMenu.reducer';
import { notificationTemplatesReducer } from './notificationTemplates.reducer';
import { notificationsReducer } from './notifications.reducer';
import { messagesReducer } from './messages.reducer';
import { keycloakSettingsReducer } from './keycloakSettings.reducer';
import { approvalTemplatesReducer } from './approvalTemplates.reducer';
import { openScriptSchedulerDataReducer } from './openScriptSchedulerDataReducer';
import { searchedDeletedElementsReducer } from './searchedDeletedElements.reducer';
import { printModelReducer } from './printModel.reducer';
import { querySelectReducer } from './querySelect.reducer';
import { selectedNodesReducer } from './selectedNodes.reducer';
import { expandedNodesReducer } from './expandedNodes.reducer';
import { renameNodeReducer } from './renameNode.reducer';
import { expandedStatusReducer } from './expandedStatus.reducer';
import { scriptQueryParamsReducer } from './scriptQueryParams.reducer';
import { userAccountReducer } from './userAccount.reducer';
import { editMatrixModelTypeWorkspaceTabReducer } from './workspaceTabData/editMatrixModelTypeWorkspaceTab/editMatrixModelTypeWorkspaceTab.reducers';
import { presetSettingsMatrixModelTypeReducer } from './presetSettings/presetSettingsMatrixModelType.reducer';
import { matrixModelTypeReducer } from './matrixModelType.reducer';
import { nodeStatisticsReducer } from './nodeStatisticsReducer';
import { undoRedoReducer } from './undoRedo.reducer';
import { matrixReducer } from '@/modules/Matrix/reducers/matrix.reducer';
import { matrixEditorReducer } from '@/modules/Matrix/reducers/matrixEditor.reducer';
import { findDuplicatesReducer } from './findDuplicates.reducer';
import { publishedModelsReducer } from './publishedModels.reducer';
import { reportModelTypeReducer } from './reportModelType.reducer';
import { presetSettingsReportModelTypeReducer } from './presetSettings/presetSettingsReportModelType.reducer';
import { editReportModelTypeWorkspaceTabReducer } from './workspaceTabData/editRecordModelTypeWorkspaceTab/editReportModelTypeWorkspaceTab.reducers';
import { SearchAipReducer } from '@/components/aip/reducers/assistantInterface.reducer';

export const appReducer: Reducer = combineReducers({
    app,
    recent: recentReducer,
    favorites: favoritesReducer,
    notification: notificationReducer,
    mainMenu: mainMenuReducer,
    schemes: tabsReducer,
    tree: treeReducer,
    selectedNodes: selectedNodesReducer,
    expandedNodes: expandedNodesReducer,
    expandedStatus: expandedStatusReducer,
    renameNode: renameNodeReducer,
    generalMenu: generalMenuReducer,
    canvasSettings: canvasSettingsReducer,
    navigator: navigatorReducer,
    contextMenu: contextMenuReducer,
    navigatorPanel: navigatorPanelReducer,
    locale: localeReducer,
    authorization: authorizationReducer,
    entities: entitiesReducer,
    dialogs: dialogsReducer,
    modelDialog: ModelDialogReducer,
    scriptExecuteDialog: ScriptExecuteDialogReducer,
    navigatorProperties: navigatorPropertiesReducer,
    databaseDialog: databaseDialogReducer,
    objectDefinitionDialog: ObjectDefinitionDialogReducer,
    folderDialog: folderDialogReducer,
    instancePermissions: instancePermissionsReducer,
    allPermissions: allPermissionsReducer,
    principals: principalsReducer,
    modelType: modelTypeReducer,
    kanbanModelType: kanbanModelTypeReducer,
    matrixModelType: matrixModelTypeReducer,
    reportModelType: reportModelTypeReducer,
    objectType: objectTypeReducer,
    presetIcon: presetIconReducer,
    presetImageGroup: presetImageGroupReducer,
    deletedPresetImageGroup: deletedPresetImageGroupReducer,
    edgeType: edgeTypeReducer,
    folderType: folderTypeReducer,
    folderTypeEditor: folderTypeEditorReducer,
    attributeType: attributeTypeReducer,
    attributeTypeGroup: attributeTypeGroupReducer,
    modelTypeGroup: modelTypeGroupReducer,
    deletedModelTypeGroup: deletedModelTypeGroupReducer,
    objectTypeGroup: objectTypeGroupReducer,
    deletedObjectTypeGroup: deletedObjectTypeGroupReducer,
    deletedEdgeTypeGroup: deletedEdgeTypeGroupReducer,
    edgeTypeGroup: edgeTypeGroupReducer,
    importDialog: ImportDialogReducer,
    model: modelReducer,
    kanban: kanbanReducer,
    objectDefinition: objectDefinitionReducer,
    script: scriptReducer,
    scriptExecution: scriptExecutionReducer,
    wiki: wikiReducer,
    kanbanCardType: kanbanCardTypeReducer,
    symbol: symbolReducer,
    symbolEditor: symbolEditorReducer,
    comments: commentsReducer,
    matrix: matrixReducer,
    matrixEditor: matrixEditorReducer,
    undoRedo: undoRedoReducer,
    admintool: adminToolReducer,
    users: usersReducer,
    groups: groupsReducer,
    licenses: licensesReducer,
    accesses: accessesReducer,
    databaseAccess: databaseAccessReducer,
    statusBar: StatusBarReducer,
    presets: presetReducer,
    treeNodeType: treeNodeTypeReducer,
    simulationModeling: simulationReducer,
    audit: auditReducer,
    copy: copyReducer,
    monitoring: monitoringReducer,
    search: SearchReducer,
    searchAip: SearchAipReducer,
    import: importReducer,
    importAris: importArisReducer,
    session: SessionsReducer,
    userProfile: userProfileReducer,
    presetSettings: combineReducers({
        presetProfile: presetProfileReducer,
        modelTypes: presetSettingsModelTypeReducer,
        kanbanModelTypes: presetSettingsKanbanModelTypeReducer,
        kanbanCardTypes: presetSettingsKanbanCardTypeReducer,
        matrixModelTypes: presetSettingsMatrixModelTypeReducer,
        reportModelTypes: presetSettingsReportModelTypeReducer,
    }),
    scriptScheduler: scriptSchedulerReducer,
    acl: aclReducer,
    serverProfiles: serverProfileReducer,
    principalServerProfile: principalServerProfileReducer,
    workspaceTabData: combineReducers({
        editModelTypeWorkspaceTab: editModelTypeWorkspaceTabReducer,
        editKanbanModelTypeWorkspaceTab: editKanbanModelTypeWorkspaceTabReducer,
        editKanbanCardTypeWorkspaceTab: editKanbanCardTypeWorkspaceTabReducer,
        editMatrixModelTypeWorkspaceTab: editMatrixModelTypeWorkspaceTabReducer,
        editReportModelTypeWorkspaceTab: editReportModelTypeWorkspaceTabReducer,
    }),
    spreadsheet: spreadsheetReducer,
    statistics: statisticsReducer,
    eventsAccess: eventsAccessReducer,
    edgeDefinition: edgeDefinitionReducer,
    changeEntityType: changeEntityTypeReducer,
    findDuplicates: findDuplicatesReducer,
    editor: editorReducer,
    modelEvents: modelEventsReducer,
    nodes: nodesReducer,
    fetchedScripts: fetchedScriptsReducer,
    externalSessions: externalSessionsReducer,
    floatingAttributesPanelData: floatingAttributesPanelReducer,
    systemProperties: systemPropertiesReducer,
    scriptContext: scriptContextReducer,
    selectObjectDefinitionDialog: selectObjectDefinitionDialogReducer,
    selectEdgeDefinitionDialog: selectEdgeDefinitionDialogReducer,
    approval: approvalReducer,
    approvalTemplates: approvalTemplatesReducer,
    dashboard: dashboardReducer,
    navigatorTreeSearch: navigatorTreeSearchReducer,
    autoSaveModelIntervalIds: autoSaveModelIntervalIdsReducer,
    subscribedNodesIds: subscribedNodesIdsReducer,
    notificationTemplates: notificationTemplatesReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    keycloakSettings: keycloakSettingsReducer,
    openScriptSchedulerData: openScriptSchedulerDataReducer,
    searchedDeletedElements: searchedDeletedElementsReducer,
    printModel: printModelReducer,
    querySelectData: querySelectReducer,
    scriptQueryParams: scriptQueryParamsReducer,
    userAccount: userAccountReducer,
    nodeStatistics: nodeStatisticsReducer,
    publishedModels: publishedModelsReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === CLEAR_STORE) {
        return appReducer(undefined, action);
    }

    if (action.type === BATCH_ACTIONS) {
        return action.payload.reduce((acc, action) => appReducer(acc, action), state);
    }

    return appReducer(state, action);
};
