import { createSelector } from 'reselect';
import { compareNodes, compareTreeObjectType } from '../models/tree';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TRootState } from '../reducers/root.reducer.types';
import { TSearchDataListItem } from '../reducers/search.reducer.types';
import { NodeId } from '../serverapi/api';

const getActiveSearch = ({ search, schemes }) => {
    const split = schemes.activeId?.id?.split('SearchTab ');

    return split?.length > 1 ? search[split[1]] : undefined;
};

const getUniqueTypes = (res: TSearchDataListItem[]): TreeItemType[] => Array.from(new Set(res.map((el) => el.type)));

export namespace SearchSelectors {
    export const getId = (state: TRootState): string => state.schemes.activeId?.id.split('SearchTab ')[1] || '';
    export const getPath = (state: TRootState): string => getActiveSearch(state)?.path;
    export const getSearchResult = (state: TRootState): TSearchDataListItem[] =>
        getActiveSearch(state)?.searchResult || [];
    export const getServerId = (state: TRootState): string => getActiveSearch(state)?.nodeId?.serverId;
    export const getNodeId = (state: TRootState): NodeId => getActiveSearch(state)?.nodeId;
    export const getLoadingStatus = (state: TRootState): boolean => getActiveSearch(state)?.isLoading;
    export const getSearchText = (state: TRootState): string => getActiveSearch(state)?.searchText;
    export const getFilter = (state: TRootState): string[] => getActiveSearch(state)?.filter || [];
    export const getFilterSearchResult = () =>
        createSelector(getSearchResult, getFilter, (search, filter) => {
            const uniqueTypes: TreeItemType[] = getUniqueTypes(search);
            const uniqueFilter: TreeItemType[] = uniqueTypes?.filter((el: TreeItemType) => filter.includes(el));
            const filterSearchResult: TSearchDataListItem[] = search
                .filter((el) => !uniqueFilter.length || uniqueFilter.includes(el.type))
                .sort(compareNodes);

            return filterSearchResult;
        });
    export const getFilterUniqueType = () =>
        createSelector(getSearchResult, (searchResult) => {
            const uniqueTypes: TreeItemType[] = getUniqueTypes(searchResult).sort(compareTreeObjectType);

            return uniqueTypes;
        });
    export const getFoundElementsCount = (state: TRootState): number | undefined =>
        getActiveSearch(state)?.foundElementsCount;
}
