import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { SearchRequest, SearchResponse } from '@/serverapi/api';

export class SearchDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getExtendedSearchResponse(searchRequest: SearchRequest): Promise<SearchResponse> {
        const api = this.getApi();
        const searchResponse: SearchResponse = await api.search.searchExtended({ body: searchRequest });

        return {
            resultList: searchResponse.resultList
                ? searchResponse.resultList.map((result) => ({
                      ...result,
                      nodeId: { ...result.nodeId, serverId: searchRequest.rootSearchNodeId.serverId },
                  }))
                : [],
            foundElementsCount: searchResponse.foundElementsCount,
        };
    }
}
